import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const imageTexasBaconRiceBowl = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/texasbaconricebowl_large.png';

const RecipePage = () => (
  <Layout
    metaTitle="The Tex Bacon Rice Bowl Recipe"
    metaDescription="Enjoy the delicious taste of thick-cut bacon with this Tex bacon rice bowl recipe. It’s easy to make and perfect for any meal!"
    metaKeywords=""
  >
    <meta property="og:image" content={imageTexasBaconRiceBowl} />
        <link rel="image_src" href={imageTexasBaconRiceBowl} />
    <div id="recipe-details-wrapper">
      <div class="image">
        <img
          src={imageTexasBaconRiceBowl}
          class="img-responsive"
          alt="The TEX Bacon and Rice Bowl"
        />
      </div>

      <div class="inner-wrapper">
        <div id="recipe-details">
          <div class="container">
            <div class="content-wrapper">
              <h1>The Tex Bacon Rice Bowl Recipe</h1>

              <div class="recipe-content">
                <p>
                  Bold Texas flavors complement hickory smoked bacon with melted
                  tomato, fresh lime, red chilies and cilantro. Serve the bacon
                  with tender rice infused with bacon pan drippings, green
                  onions, black beans, roasted corn and lime.
                </p>
              </div>
            </div>

            <div class="timing row">
              <div class="col-md-4 col-xs-12 time">
                <div class="icon">
                  <img
                    src="/tysonscore3.azureedge.net/assets/media/prepIcon.png"
                    alt="Prep Time"
                  />
                </div>
                <div class="details">
                  <h4>Prep Time:</h4>
                  <p>10&nbsp;minutes </p>
                </div>
              </div>

              <div class="col-md-4 col-xs-12 time">
                <div class="icon">
                  <img
                    src="/tysonscore3.azureedge.net/assets/media/cookIcon.png"
                    alt="Cook Time"
                  />
                </div>
                <div class="details">
                  <h4>Cook Time:</h4>
                  <p>25&nbsp;minutes </p>
                </div>
              </div>

              <div class="col-md-4 col-xs-12 time">
                <div class="icon">
                  <img
                    src="/tysonscore3.azureedge.net/assets/media/serveIcon.png"
                    alt="Serving Size"
                  />
                </div>
                <div class="details">
                  <h4>Serving Size:</h4>
                  <p>4 </p>
                </div>
              </div>
            </div>

      
          </div>
        </div>

        <div class="ingredients-wrapper recipe-section">
          <div class="container">
            <h2>Ingredients</h2>
            <div class="row ingredients">
              <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                1.5 pounds Wright® Brand Hickory Smoked Bacon{" "}
                <span class="uom"></span>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                1 cup diced tomatoes <span class="uom"></span>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                1 lime, juiced <span class="uom"></span>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                ¼ cup water <span class="uom"></span>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                ¼ teaspoon chili powder <span class="uom"></span>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                ¼ cup freshly chopped cilantro <span class="uom"></span>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                2 cups dry white rice <span class="uom"></span>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                2 tablespoons pan drippings <span class="uom"></span>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                1 (15 oz) can unsalted corn <span class="uom"></span>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                1 (15 oz) can unsalted black beans <span class="uom"></span>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                ¼ cup sliced green onions <span class="uom"></span>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                1 lime, wedges <span class="uom"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="instructions-wrapper recipe-section">
          <div class="container">
            <h2>Directions</h2>
            <div class="instructions">
              <p>
                1. Cook the bacon according to skillet instructions, reserve pan
                drippings.
              </p>
              <p>
                2. In the same skillet, add the tomatoes, lime juice, water and
                chili powder with the bacon. Let the tomatoes melt with the
                bacon until the liquid is reduced to almost nothing.
              </p>
              <p>
                3. While the bacon is cooking, start cooking the rice according
                to package instructions.
              </p>
              <p>
                4. Heat a clean skillet over medium-high heat. Add pan drippings
                with corn and black beans, cooking until roasted about 2-3
                minutes. Finish with green onions and toss with rice.
              </p>
              <p>
                5. Serve the rice in four bowls and top with tomato bacon.
                Finish with squeezed lime wedges.
              </p>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </div>

    <article class="locator-callout">
      <div class="quick-form-container">
        <div class="product-locator-search">
          <h2>Find Our Products</h2>
          <form data-url="/locator/" action="/locator/" method="get">
            <input
              Value="ANY_ANY"
              id="PROD"
              name="PROD"
              type="hidden"
              value="ANY_ANY"
            />
            <div class="input-wrapper">
              <input
                data-val="true"
                data-val-regex="Please enter a 5-digit ZIP code"
                data-val-regex-pattern="^\d{5}"
                data-val-required="Please enter a 5-digit ZIP code"
                id="ZIP"
                maxLength="5"
                name="ZIP"
                placeholder="Enter Zip"
                type="tel"
                defaultValue=""
              />
              <input type="submit" value="Locate Bacon"></input>
              <span
                class="field-validation-valid"
                data-valmsg-for="ZIP"
                data-valmsg-replace="true"
              ></span>
            </div>
          </form>
        </div>
      </div>
    </article>
  </Layout>
)

export default RecipePage
